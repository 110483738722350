import React, { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Utils
import { getCookie, validateEmail } from "../../../utils/utils";
import { getBackendUrl } from "../../../utils/routes";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Redux
import store from "../../../redux/store";
import { createMessage } from "../../../actions/messages";

// Spinner
import LoadingOverlay from "../../Common/LoadingOverlay";

// Images
import linkedinCertDummy from "../../../Assets/Images/Apps/LinkedinCertificateLinkGenerator/linkedin-cert-dummy.jpg";
import linkedinCertDummyGer from "../../../Assets/Images/Apps/LinkedinCertificateLinkGenerator/linkedin-cert-dummy_ger.jpg";

// GA
// import ReactGA from "react-ga";

// Init Google Analytics
// ReactGA.initialize("UA-161880607-5", {
//     debug: true,
//     gaOptions: {
//         siteSpeedSampleRate: 100,
//     },
// });

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "white",
        left: 0,
        top: 0,
        position: "absolute",
        width: "100vw",
        height: "100vh",
        padding: 50,
    },
    h1: {
        textAlign: "center",
        fontWeight: 500,
    },
    h2: {
        textAlign: "center",
        fontSize: "1.1rem",
        fontWeight: 400,
        color: "grey",
        marginTop: 30,
    },
    h3: {
        fontSize: "1.3rem",
        marginBottom: 20,
    },
    dummyImage: {
        borderRadius: 4,
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        width: "100%",
        // marginLeft: "25%",
    },
    containerWrap: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50,
    },
    form: {
        backgroundColor: "white",
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        border: "solid 1px lightgray",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: 50,
    },
    input: {
        marginBottom: 15,
    },
    output: {
        minHeight: 100,
        backgroundColor: "white",
        border: "solid 1px lightgray",
        borderRadius: 8,
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        width: "70%",
        marginLeft: "15%",
        padding: 30,
        marginTop: 50,
    },
    linkContainer: {
        border: "solid 1px lightgray",
        borderRadius: 8,
        minHeight: 50,
        padding: 20,
    },
    margin10: {
        height: 10,
    },
    margin20: {
        height: 20,
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
        borderBottom: "solid 1px lightgrey",
        width: "80%",
        marginLeft: "10%",
    },
    show: {
        display: "block",
    },
    hide: {
        display: "none",
    },
    forceSelect: {
        WebkitUserSelect: "auto",
        MozUserSelect: "auto",
        MsUserSelect: "auto",
        userSelect: "auto",
    },
}));

const LinkedinCertificateLinkGenerator = (props) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { privateRoute } = props;
    console.log(privateRoute);

    const [certName, setCertName] = useState("");
    const [orgaId, setOrgaId] = useState("");
    const [issueYear, setIssueYear] = useState("");
    const [certLink, setCertLink] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [clickCount, setClickCount] = useState(getCookie("clickCount") || 0);
    const [leadFirstName, setLeadFirstName] = useState("");
    const [leadLastName, setLeadLastName] = useState("");
    const [leadEmail, setLeadEmail] = useState("");
    const [init, setInit] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const [brandVariant] = useState(urlParams.get("schulungszertifikate") ? "schulungszertifikate" : "virtualbadge");

    useEffect(() => {
        if (init) {
            console.log(brandVariant);
            i18n.changeLanguage(brandVariant === "schulungszertifikate" ? "de" : "en");
            setInit(false);
        }
    });

    useEffect(() => {
        document.cookie = `clickCount=${parseInt(clickCount)}; path=/;`;
    }, [clickCount]);

    const updateLink = () => {
        setCertLink("");
        setShowSpinner(true);
        setClickCount(clickCount + 1);

        if (certName === "")
            store.dispatch(
                createMessage(t("linkedin_certificate_generator.messages.please_add_a_certificate_name"), "info")
            );

        // Check if orgaid is a name or id (can be both)
        // To achieve this we parse the string to int
        // If the value is NaN we know it was not a number/id
        // Therefore we need to negate the whole expression
        // to find out wheter it is and ID or not.
        let isID = !isNaN(parseInt(orgaId));

        setTimeout(() => {
            setShowSpinner(false);
            setCertLink(
                `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certName}&${
                    isID ? "organizationId" : "organizationName"
                }=${orgaId}&issueYear=${issueYear}`
            );
        }, 1000);
    };

    const sendLead = () => {
        if (leadEmail === "" || !validateEmail(leadEmail)) {
            store.dispatch(
                createMessage(t("linkedin_certificate_generator.messages.please_enter_a_valid_email_address"), "error")
            );
            return;
        }

        // Add contact to crm
        var contact = {};
        contact.email = leadEmail;
        contact.first_name = leadFirstName;
        contact.last_name = leadLastName;
        // li-cert-generator    - virtualbadge.io lead
        // li-cert-generator-sz - schulungszertifikate.de lead
        contact.tag = brandVariant === "virtualbadge" ? "li-cert-generator" : "li-cert-generator-sz";

        console.log(contact);

        let env = ["localhost", "127.0.0.1"].includes(window.location.hostname) ? "local" : "prod";
        let API_URL = getBackendUrl(env, "virtualbadge");

        axios.post(`${API_URL}/api/public/put_lead_into_crm`, { ...contact, identifier: "VB-CV1LP1ALL-9999" });

        document.cookie = "leadCompleted=true; path=/;";
        setClickCount(clickCount + 1);
        store.dispatch(
            createMessage(
                t("linkedin_certificate_generator.messages.you_can_now_generate_as_many_certificates_as_you_wish"),
                "success"
            )
        );

        // Track event in GA
        // ReactGA.event({
        //     category: "User",
        //     action: `LINKEDIN CERT LINK GEN: Lead Captured (${brandVariant})`,
        //     label: `Environment: ${env}`,
        // });
    };

    return (
        <div className={classes.background}>
            {/* <h1 className={classes.h1}>LinkedIn Certificate Link Generator</h1> */}
            <h2 className={classes.h2}>{t("linkedin_certificate_generator.texts.example", "Example:")}</h2>
            <img
                className={classes.dummyImage}
                src={brandVariant === "virtualbadge" ? linkedinCertDummy : linkedinCertDummyGer}
            />

            {!getCookie("leadCompleted") && getCookie("clickCount") > 1 && !privateRoute ? (
                <div className={classes.containerWrap}>
                    <div className={classes.form}>
                        <h3 className={clsx(classes.h3, "text-center")}>
                            {t("linkedin_certificate_generator.texts.gain_access", "Gain access")}
                        </h3>
                        <p className="text-muted text-center">
                            {t(
                                "linkedin_certificate_generator.texts.create_cert",
                                "Create your free LinkedIn certificate"
                            )}
                        </p>
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.first_name")}
                            required
                            value={leadFirstName}
                            onChange={(e) => {
                                setLeadFirstName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.last_name")}
                            required
                            value={leadLastName}
                            onChange={(e) => {
                                setLeadLastName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            type="email"
                            label={t("linkedin_certificate_generator.labels.email")}
                            required
                            value={leadEmail}
                            onChange={(e) => {
                                setLeadEmail(e.target.value);
                            }}
                        />
                        <div className={classes.margin20} />
                        <Button type="submit" color="primary" variant="contained" onClick={sendLead}>
                            {t("linkedin_certificate_generator.buttons.get_access")}
                        </Button>
                        <div className={classes.margin10} />
                        <small className="text-muted text-center">
                            {t("linkedin_certificate_generator.texts.get_access_explanaition")}
                        </small>
                    </div>
                </div>
            ) : (
                <div className={classes.containerWrap}>
                    <div className={classes.form}>
                        <h3 className={classes.h3}>
                            {t("linkedin_certificate_generator.texts.instruction_header_1")}
                            <b>{t("linkedin_certificate_generator.texts.instruction_header_2")}</b>
                            {t("linkedin_certificate_generator.texts.instruction_header_3")}:
                        </h3>
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.certificate_name")}
                            required
                            value={certName}
                            onChange={(e) => {
                                setCertName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.organisation_id")}
                            onChange={(e) => {
                                setOrgaId(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.issue_year")}
                            onChange={(e) => {
                                setIssueYear(e.target.value);
                            }}
                        />
                        <small style={{ textAlign: "right", color: "gray" }}>
                            * {t("linkedin_certificate_generator.texts.mandatory")}
                        </small>
                        <div className={classes.margin20} />
                        <Button type="submit" color="primary" variant="contained" onClick={updateLink}>
                            {t("linkedin_certificate_generator.buttons.generate_link")}
                        </Button>
                        <div className={classes.divider} />
                        <h3 className={clsx(classes.h3, "text-center")}>
                            {" "}
                            {t("linkedin_certificate_generator.texts.link_for_your_participants")}:
                        </h3>
                        {/* TODO: add pseudo loading spinner for 1s */}
                        <div className={clsx(classes.linkContainer, classes.forceSelect)}>
                            {showSpinner && <LoadingOverlay marginTop={"0px"} noMessage />}
                            <span className={classes.show}>
                                <a className={classes.link} target="_blank" href={certLink}>
                                    <b>{certLink}</b>
                                </a>
                            </span>
                        </div>
                        <div className={classes.margin20} />
                        <small className="text-muted">
                            {t("linkedin_certificate_generator.texts.link_explanation")}
                        </small>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LinkedinCertificateLinkGenerator;
