import React from "react";
// Redux
import { Provider } from "react-redux";
import store from "../redux/store";

// components
import NoRouter from "./Common/Routing/NoRouter";
import Alerts from "./Common/Alerts";

interface Props {
    Component: (props: Props) => JSX.Element;
}

function AppNoRouter({ Component, ...props }: Props) {
    return (
        <Provider store={store}>
            <div className="pc-app">
                <Alerts />
                <NoRouter component={Component} />
            </div>
        </Provider>
    );
}

export default AppNoRouter;
